import React, { useMemo, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { identity } from 'lodash'
import { getOr } from 'lodash/fp'

import {
  FILTERS_TYPES,
  FILTERS_VALUES,
  FILTER_ITEMS,
  SELECT_TYPE,
  FILTERS_TEST_IDS,
} from 'components/Filters/consts'
import { isAnyFilterSelected } from 'views/Products/utils'
import {
  FILTER_EVENTS,
  trackCatalogFilters,
  trackSimpleEvent,
} from 'services/analytics'

import {
  sortedFilterItems,
  findFilter,
  getFilterItemCount,
  mapAvailableFilters,
  getAvailableFilterItems,
} from './utils'
import Filter from './Filter'
import messages from './messages'
import {
  FiltersWrapper,
  FiltersControl,
  Title,
  ButtonFiltersWrapper,
  StyledButtonFilter,
} from './styles'

const Filters = ({
  availableFilters = [],
  selectedFilters = {},
  handleFiltersApply = identity,
  handleFiltersClear = identity,
  isTitle = true,
  isCategoryFilter = false,
  calculatePositionFromParent = false,
  portalNode = null,
  className,
  eventParams,
}) => {
  const { formatMessage } = useIntl()
  const {
    categories,
    brands,
    onStock,
    promotion,
    storageTypes,
    offerFeatures,
    productFeatures,
    cuisineFeatures,
  } = useMemo(() => mapAvailableFilters(availableFilters), [availableFilters])

  const [
    selectedBrandsMap,
    selectedCategoriesMap,
    selectedProductFeaturesMap,
    selectedOfferFeaturesMap,
    selectedCuisineFeaturesMap,
  ] = [
    getOr({}, FILTERS_TYPES.BRANDS, selectedFilters),
    getOr({}, FILTERS_TYPES.CATEGORIES, selectedFilters),
    getOr({}, FILTERS_TYPES.PRODUCT_FEATURES, selectedFilters),
    getOr({}, FILTERS_TYPES.OFFER_FEATURES, selectedFilters),
    getOr({}, FILTERS_TYPES.CUISINE_FEATURES, selectedFilters),
  ]

  const brandsSorted = useMemo(
    () => sortedFilterItems(selectedBrandsMap, brands),
    [brands, selectedBrandsMap],
  )
  const categoriesSorted = useMemo(
    () => sortedFilterItems(selectedCategoriesMap, categories),
    [categories, selectedCategoriesMap],
  )

  const availableSelectedBrandsMap = useMemo(
    () => getAvailableFilterItems(brands, selectedBrandsMap),
    [brands, selectedBrandsMap],
  )
  const availableSelectedCategoriesMap = useMemo(
    () => getAvailableFilterItems(categories, selectedCategoriesMap),
    [categories, selectedCategoriesMap],
  )

  const {
    BESTSELLER: bestsellerFilterValue,
    NOVELTY: noveltyFilterValue,
    BIDMARKET: bidmarketFilterValue,
    RECOMMENDATION: recommendationFilterValue,
  } = FILTERS_VALUES.OFFER_FEATURES
  const offerFeatureCounts = {
    [bestsellerFilterValue]: getFilterItemCount(
      offerFeatures,
      bestsellerFilterValue,
    ),
    [noveltyFilterValue]: getFilterItemCount(offerFeatures, noveltyFilterValue),
    [bidmarketFilterValue]: getFilterItemCount(
      offerFeatures,
      bidmarketFilterValue,
    ),
    [recommendationFilterValue]: getFilterItemCount(
      offerFeatures,
      recommendationFilterValue,
    ),
  }
  const handleApplyCallback = useCallback(
    (name, filterParams, isMultiple) => {
      trackCatalogFilters({
        filterName: name,
        filterValues: filterParams,
        offerFeatureCounts,
        params: eventParams,
      })

      handleFiltersApply(name, filterParams, isMultiple)
    },
    [handleFiltersApply, eventParams, offerFeatureCounts],
  )

  const handleCategoriesShow = () => {
    trackSimpleEvent(FILTER_EVENTS.clickedCategories, eventParams)
  }

  const handleBrandsShow = () => {
    trackSimpleEvent(FILTER_EVENTS.clickedBrands, eventParams)
  }

  const handleProductFeaturesShow = () => {
    trackSimpleEvent(FILTER_EVENTS.clickedProductFeatures, eventParams)
  }

  const handleOfferFeaturesShow = () => {
    trackSimpleEvent(FILTER_EVENTS.clickedOfferFeatures, eventParams)
  }

  const handleCuisineFeaturesShow = () => {
    trackSimpleEvent(FILTER_EVENTS.clickedCuisines, eventParams)
  }

  return (
    <FiltersWrapper className={className} id="filters-wrapper">
      {isTitle && (
        <FiltersControl data-test-id="filters-title">
          <Title>{formatMessage(messages.title)}</Title>
        </FiltersControl>
      )}
      {isCategoryFilter && (
        <Filter
          testId={FILTERS_TEST_IDS.CATEGORIES}
          searchActive
          title={formatMessage(messages.categories)}
          name={FILTERS_TYPES.CATEGORIES}
          items={categoriesSorted}
          selectedItems={availableSelectedCategoriesMap}
          onApplyFilters={handleApplyCallback}
          onDropdownShow={handleCategoriesShow}
          calculatePositionFromParent={calculatePositionFromParent}
          portalNode={portalNode}
        />
      )}
      <ButtonFiltersWrapper hasSpacingLeft={isCategoryFilter}>
        <Filter
          testId={FILTERS_TEST_IDS.ON_STOCK}
          title={formatMessage(messages.onStock)}
          name={FILTERS_TYPES.ON_STOCK}
          defaultFilter={findFilter(onStock, FILTERS_VALUES.ON_STOCK.ALL)}
          activeFilter={findFilter(onStock, FILTERS_VALUES.ON_STOCK.AVAILABLE)}
          selectedItems={getOr(
            { [FILTER_ITEMS.ALL]: true },
            FILTERS_TYPES.ON_STOCK,
            selectedFilters,
          )}
          onApplyFilters={handleApplyCallback}
          type={SELECT_TYPE.BUTTON}
        />
      </ButtonFiltersWrapper>
      <ButtonFiltersWrapper hasSpacingLeft>
        <Filter
          testId={FILTERS_TEST_IDS.PROMOTION}
          title={formatMessage(messages.promotion)}
          name={FILTERS_TYPES.PROMOTION}
          defaultFilter={findFilter(promotion, FILTERS_VALUES.PROMOTION.ALL)}
          activeFilter={{ value: 1, label: 'filter.promotion.available' }}
          selectedItems={getOr(
            { [FILTER_ITEMS.ALL]: true },
            FILTERS_TYPES.PROMOTION,
            selectedFilters,
          )}
          onApplyFilters={handleApplyCallback}
          type={SELECT_TYPE.BUTTON}
          isAlwaysActive
        />
      </ButtonFiltersWrapper>
      <ButtonFiltersWrapper hasSpacingLeft>
        <Filter
          testId={FILTERS_TEST_IDS.CLEARANCE}
          title={formatMessage(messages.clearance)}
          name={FILTERS_TYPES.CLEARANCE}
          defaultFilter={findFilter(promotion, FILTERS_VALUES.CLEARANCE.ALL)}
          activeFilter={{ value: 1, label: 'filter.clearance.available' }}
          selectedItems={getOr(
            { [FILTER_ITEMS.ALL]: true },
            FILTERS_TYPES.CLEARANCE,
            selectedFilters,
          )}
          onApplyFilters={handleApplyCallback}
          type={SELECT_TYPE.BUTTON}
          isAlwaysActive
        />
      </ButtonFiltersWrapper>
      <ButtonFiltersWrapper hasSpacingLeft>
        <Filter
          testId={FILTERS_TEST_IDS.OFFER_FEATURES}
          title={formatMessage(messages.offerFeatures)}
          name={FILTERS_TYPES.OFFER_FEATURES}
          items={offerFeatures}
          selectedItems={selectedOfferFeaturesMap}
          onApplyFilters={handleApplyCallback}
          onDropdownShow={handleOfferFeaturesShow}
          calculatePositionFromParent={calculatePositionFromParent}
          portalNode={portalNode}
          hideLabelsCount
        />
      </ButtonFiltersWrapper>
      <ButtonFiltersWrapper hasSpacingLeft>
        <Filter
          testId={FILTERS_TEST_IDS.PRODUCT_FEATURES}
          title={formatMessage(messages.productFeatures)}
          name={FILTERS_TYPES.PRODUCT_FEATURES}
          items={productFeatures}
          selectedItems={selectedProductFeaturesMap}
          onApplyFilters={handleApplyCallback}
          onDropdownShow={handleProductFeaturesShow}
          calculatePositionFromParent={calculatePositionFromParent}
          portalNode={portalNode}
        />
      </ButtonFiltersWrapper>
      <ButtonFiltersWrapper hasSpacingLeft>
        <Filter
          testId={FILTERS_TEST_IDS.CUISINE_FEATURES}
          title={formatMessage(messages.cuisines)}
          name={FILTERS_TYPES.CUISINE_FEATURES}
          items={cuisineFeatures}
          selectedItems={selectedCuisineFeaturesMap}
          onApplyFilters={handleApplyCallback}
          onDropdownShow={handleCuisineFeaturesShow}
          calculatePositionFromParent={calculatePositionFromParent}
          portalNode={portalNode}
        />
      </ButtonFiltersWrapper>
      <ButtonFiltersWrapper hasSpacingLeft className="items-center">
        <Filter
          testId={FILTERS_TEST_IDS.ROOM_TEMP}
          title={formatMessage(messages.roomTemp)}
          name={FILTERS_TYPES.STORAGE_TYPES}
          activeFilter={findFilter(
            storageTypes,
            FILTERS_VALUES.STORAGE_TYPES.ROOM_TEMP,
          )}
          selectedItems={getOr(
            {},
            FILTERS_TYPES.STORAGE_TYPES,
            selectedFilters,
          )}
          onApplyFilters={handleApplyCallback}
          tooltipText={formatMessage(messages.roomTempDesc)}
          type={SELECT_TYPE.GROUP_BUTTON}
        />
        <Filter
          testId={FILTERS_TEST_IDS.CHILLED}
          title={formatMessage(messages.chilled)}
          name={FILTERS_TYPES.STORAGE_TYPES}
          activeFilter={findFilter(
            storageTypes,
            FILTERS_VALUES.STORAGE_TYPES.CHILLED,
          )}
          selectedItems={getOr(
            {},
            FILTERS_TYPES.STORAGE_TYPES,
            selectedFilters,
          )}
          onApplyFilters={handleApplyCallback}
          tooltipText={formatMessage(messages.chilledDesc)}
          type={SELECT_TYPE.GROUP_BUTTON}
        />
        <Filter
          testId={FILTERS_TEST_IDS.FROZEN}
          title={formatMessage(messages.frozen)}
          name={FILTERS_TYPES.STORAGE_TYPES}
          activeFilter={findFilter(
            storageTypes,
            FILTERS_VALUES.STORAGE_TYPES.FROZEN,
          )}
          selectedItems={getOr(
            {},
            FILTERS_TYPES.STORAGE_TYPES,
            selectedFilters,
          )}
          onApplyFilters={handleApplyCallback}
          tooltipText={formatMessage(messages.frozenDesc)}
          type={SELECT_TYPE.GROUP_BUTTON}
        />
      </ButtonFiltersWrapper>
      <ButtonFiltersWrapper hasSpacingLeft>
        <Filter
          testId={FILTERS_TEST_IDS.BRANDS}
          searchActive
          title={formatMessage(messages.brands)}
          name={FILTERS_TYPES.BRANDS}
          items={brandsSorted}
          showSelectedSection
          selectedItems={availableSelectedBrandsMap}
          onApplyFilters={handleApplyCallback}
          onDropdownShow={handleBrandsShow}
          calculatePositionFromParent={calculatePositionFromParent}
          portalNode={portalNode}
        />
      </ButtonFiltersWrapper>
      {isAnyFilterSelected(selectedFilters) && (
        <ButtonFiltersWrapper>
          <StyledButtonFilter
            data-test-id="clear_filters"
            onClick={handleFiltersClear}
          >
            {formatMessage(messages.clearAll)}
          </StyledButtonFilter>
        </ButtonFiltersWrapper>
      )}
    </FiltersWrapper>
  )
}

export default Filters

import {
  LogisticIcon,
  DeliveryIcon,
  AlertIcon,
  AlcoholIcon,
  InfoIcon,
  MarketingIcon,
  PromoIcon,
} from 'components/Icons/Notifications'
import theme from 'theme'
import messages from './NotificationCenterModal/messages'

const FEED_MESSAGE_STYLING_TYPE = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
  NEUTRAL: 'neutral',
  INFO: 'info',
}

export const FEED_MESSAGE_TYPES = {
  logisticLimitNotSet: 'logistic-limit-not-set',
  productBackForStore: 'product-back-for-store-notifications',
  productWithdrawn: 'product-withdrawn-notifications',
  productAddedToCatalog: 'product-added-to-catalog-notifications',
  alcoholLicenseRenewal: 'alcohol-license-date-finish',
  alcoholLicenseFee: 'alcohol-license-payment-date-finish',
  campaignInfo: 'campaign-information-notifications',
  campaignMarketing: 'campaign-marketing-notifications',
  promotional: 'promotional-notifications',
}

export const FEED_MESSAGE_STYLING_CONFIG = {
  [FEED_MESSAGE_STYLING_TYPE.POSITIVE]: {
    bgColor: theme.colors.greenLight5,
    iconColors: {
      primary: theme.colors.secondary,
      secondary: '#A2E2B5',
    },
  },
  [FEED_MESSAGE_STYLING_TYPE.NEGATIVE]: {
    bgColor: '#FF4C001C',
    iconColors: {
      primary: theme.colors.redLight,
      secondary: '#E9B9B6',
    },
  },
  [FEED_MESSAGE_STYLING_TYPE.NEUTRAL]: {
    bgColor: theme.colors.semiTranspBg.grayLight,
    iconColors: {
      primary: theme.colors.primary,
      secondary: theme.colors.gray5,
    },
  },
  [FEED_MESSAGE_STYLING_TYPE.INFO]: {
    bgColor: theme.colors.infoBlue4,
    iconColors: {
      primary: theme.colors.infoBlue2,
      secondary: '#CBD4FF',
    },
  },
}

export const FEED_MESSAGE_MAP = {
  [FEED_MESSAGE_TYPES.logisticLimitNotSet]: {
    titleKey: messages.unmetLogisticLimitTitle,
    IconComponent: LogisticIcon,
    stylingType: FEED_MESSAGE_STYLING_TYPE.NEGATIVE,
  },
  [FEED_MESSAGE_TYPES.productBackForStore]: {
    titleKey: messages.productBackForStoreTitle,
    IconComponent: DeliveryIcon,
    stylingType: FEED_MESSAGE_STYLING_TYPE.INFO,
  },
  [FEED_MESSAGE_TYPES.productWithdrawn]: {
    titleKey: messages.productWithdrawnTitle,
    IconComponent: AlertIcon,
    stylingType: FEED_MESSAGE_STYLING_TYPE.INFO,
  },
  [FEED_MESSAGE_TYPES.productAddedToCatalog]: {
    titleKey: messages.productBackForStoreTitle,
    IconComponent: DeliveryIcon,
    stylingType: FEED_MESSAGE_STYLING_TYPE.INFO,
  },
  [FEED_MESSAGE_TYPES.alcoholLicenseFee]: {
    titleKey: messages.alcoholLicenseFee,
    IconComponent: AlcoholIcon,
    stylingType: FEED_MESSAGE_STYLING_TYPE.NEUTRAL,
  },
  [FEED_MESSAGE_TYPES.alcoholLicenseRenewal]: {
    titleKey: messages.alcoholLicenseRenewal,
    IconComponent: AlcoholIcon,
    stylingType: FEED_MESSAGE_STYLING_TYPE.NEUTRAL,
  },
  [FEED_MESSAGE_TYPES.campaignInfo]: {
    IconComponent: InfoIcon,
    stylingType: FEED_MESSAGE_STYLING_TYPE.NEUTRAL,
  },
  [FEED_MESSAGE_TYPES.campaignMarketing]: {
    IconComponent: MarketingIcon,
    stylingType: FEED_MESSAGE_STYLING_TYPE.POSITIVE,
  },
  [FEED_MESSAGE_TYPES.promotional]: {
    IconComponent: PromoIcon,
    stylingType: FEED_MESSAGE_STYLING_TYPE.POSITIVE,
  },
}

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router'

import { categoryGroupActions } from 'containers/Categories/actions'
import { categoryGroupSelector } from 'containers/Categories/selectors'
import { BreadCrumb } from 'components/BreadCrumbs'
import { useQueryFilters } from 'hooks'
import { ROUTES } from 'consts'
import { retailTrackCategoryView } from 'services/analytics/retail'

import ProductsBreadcrumbs from './ProductsBreadcrumbs'
import Title from './Title'
import {
  generatePathWithSearch,
  useCatalogTitle,
  useFetchOnQueryChange,
} from './utils'

const CategoryGroup = ({ id, isPDP, className }) => {
  useFetchOnQueryChange({ actions: categoryGroupActions, id })
  const { searchQuery } = useQueryFilters()

  const { name, parentCategoryId, parentCategoryName } = useSelector(
    categoryGroupSelector,
  )

  const title = useCatalogTitle(name)

  useEffect(
    () => {
      if (parentCategoryName && name && !isPDP) {
        retailTrackCategoryView({
          categoryName: parentCategoryName,
          groupName: name,
        })
      }
    },
    [parentCategoryName, name, isPDP],
  )

  return (
    <>
      <ProductsBreadcrumbs className={className}>
        {!!parentCategoryName && (
          <>
            <BreadCrumb
              to={generatePathWithSearch(ROUTES.PRODUCTS, {
                category: parentCategoryId,
                search: searchQuery,
              })}
            >
              {parentCategoryName}
            </BreadCrumb>

            {isPDP && (
              <BreadCrumb
                to={generatePath(ROUTES.PRODUCTS, {
                  category: parentCategoryId,
                  group: id,
                })}
              >
                {name}
              </BreadCrumb>
            )}
          </>
        )}
      </ProductsBreadcrumbs>

      {!isPDP && <Title title={title} />}
    </>
  )
}

export default CategoryGroup

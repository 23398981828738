import React from 'react'
import { useParams } from 'react-router'

import Filters from 'components/Filters'
import { EVENT_ORIGIN } from 'services/analytics'

import { TemplateFiltersContainer } from './styles'

const TemplateFilters = ({
  filteringSettings,
  updateFilteringSettings,
  availableFilters,
  isTemplateAutomatic,
  scrollToTopOfList,
}) => {
  const { id } = useParams()

  const resetFilters = () => {
    updateFilteringSettings({
      filters: {},
    })
  }

  const handleFiltersApply = (name, filters, isMultipleFilters) => {
    scrollToTopOfList()

    const newSelectedFilters = isMultipleFilters
      ? {
          ...filteringSettings.filters,
          ...filters,
        }
      : {
          ...filteringSettings.filters,
          [name]: { ...filters },
        }

    updateFilteringSettings({
      filters: newSelectedFilters,
      search: '',
    })
  }

  return (
    <TemplateFiltersContainer>
      <Filters
        isCategoryFilter
        isTitle={false}
        handleFiltersClear={resetFilters}
        calculatePositionFromParent
        portalNode={document.getElementById('template-list-toolbar')}
        handleFiltersApply={handleFiltersApply}
        selectedFilters={filteringSettings.filters}
        availableFilters={availableFilters}
        eventParams={{
          origin: EVENT_ORIGIN.TEMPLATES,
          template_type: isTemplateAutomatic ? 'automatic' : 'manual',
          template_id: id,
        }}
      />
    </TemplateFiltersContainer>
  )
}

export default TemplateFilters

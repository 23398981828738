import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router'

import { useContentful } from 'utils/contentful'
import { useRemoteConfig } from 'containers/RemoteConfigProvider'
import { ROUTES } from 'consts'

import { parseMaintenanceToast } from './utils'

const CONFIG_MAP = {
  [ROUTES.INVOICES.ROOT]: 'maintenanceToastPayments',
  [ROUTES.CART]: 'maintenanceToastCart',
}

const MaintenanceToast = () => {
  const { config } = useRemoteConfig()
  const { pathname } = useLocation()

  const maintenanceToastConfig = useMemo(
    () => {
      const scopedToastKey = CONFIG_MAP[pathname]
      const scopedConfig = config?.[scopedToastKey]

      if (!scopedConfig?.enabled) {
        return config.maintenanceToast
      }

      return scopedConfig
    },
    [config, pathname],
  )

  const { data: { text, theme } = {}, fetchEntries } = useContentful({
    clientQuery: {
      content_type: 'maintenanceToast',
      include: 1, // include 1 level to get the linked theme
    },
    responseParser: parseMaintenanceToast,
  })

  useEffect(
    () => {
      if (maintenanceToastConfig?.enabled) {
        fetchEntries({
          'fields.id': maintenanceToastConfig.contentfulThemeSlug,
        })
      }
    },
    [maintenanceToastConfig],
  )

  if (maintenanceToastConfig?.enabled && text) {
    return (
      <div
        data-test-id="maintenance-toast"
        className="flex w-full justify-center py-2 px-4 bg-red-100"
        style={{
          backgroundColor: theme?.backgroundColor,
        }}
      >
        <div className="flex max-w-[1440px] lg:py-0.5 lg:px-2">
          <span
            className="text-13 text-red-700"
            style={{ color: theme?.color }}
          >
            {text}
          </span>
        </div>
      </div>
    )
  }

  return null
}

export default MaintenanceToast

import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import qs from 'qs'

import { selectAvailableFiltersData } from 'containers/Products/Filters/selectors'
import { makeSelectedFiltersParams } from 'components/Filters/utils'
import { EVENT_ORIGIN } from 'services/analytics'

import Filters from 'components/Filters'
import FiltersPagination from './components/FiltersPagination'
import {
  FiltersContainer,
  FiltersWrapper,
  FiltersPaginationWrapper,
} from './styles'

const CatalogFilters = ({
  onPageChange,
  handleFiltersClear,
  current,
  totalPages,
  totalCount,
  selectedFilters,
  sectionName,
  queryParams,
}) => {
  const navigate = useNavigate()
  const [portalNode, setPortalNode] = useState(null)
  const containerRef = useRef(null)

  const availableFilters = useSelector(selectAvailableFiltersData)

  useEffect(() => {
    setPortalNode(containerRef?.current)
  }, [])

  const handleFiltersApply = useCallback(
    (name, filters) => {
      const newSelected = makeSelectedFiltersParams(availableFilters, {
        ...selectedFilters,
        [name]: filters,
      })
      const newQueries = qs.stringify(
        {
          ...queryParams,
          filters: {
            ...newSelected,
          },
        },
        { encode: false, arrayFormat: 'brackets' },
      )
      navigate({ search: newQueries }, { replace: true })
    },
    [sectionName, availableFilters, navigate],
  )

  return (
    <FiltersWrapper>
      <FiltersContainer ref={containerRef}>
        <Filters
          calculatePositionFromParent
          portalNode={portalNode}
          handleFiltersClear={handleFiltersClear}
          availableFilters={availableFilters}
          selectedFilters={selectedFilters}
          handleFiltersApply={handleFiltersApply}
          eventParams={{
            origin: queryParams.search
              ? EVENT_ORIGIN.SEARCH_RESULTS
              : EVENT_ORIGIN.CATALOG,
          }}
        />
        {!!totalCount && (
          <FiltersPaginationWrapper>
            <FiltersPagination
              isNav
              {...{ totalCount, totalPages, current, onPageChange }}
            />
          </FiltersPaginationWrapper>
        )}
      </FiltersContainer>
    </FiltersWrapper>
  )
}

export default CatalogFilters

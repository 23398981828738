import React from 'react'

import { categoriesListActions } from 'containers/Categories/actions'

import ProductsBreadcrumbs from './ProductsBreadcrumbs'
import Title from './Title'
import { useCatalogTitle, useFetchOnQueryChange } from './utils'

const AllProducts = ({ isPDP, className }) => {
  const title = useCatalogTitle()

  useFetchOnQueryChange({ actions: categoriesListActions })

  return (
    <>
      <ProductsBreadcrumbs isAllProducts className={className} />
      {!isPDP && <Title title={title} />}
    </>
  )
}

export default AllProducts

import React from 'react'

import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'
import ProductImage, {
  PRODUCT_IMAGE_SIZES,
} from 'components/Product/ProductImage'
import { cn } from 'utils'

const ImageSliderControls = ({
  handleSelectImage,
  selectedIndex,
  product,
  isProductDisabled,
  snaps,
}) => {
  const isTablet = useOfMinWidth(APP_BREAKPOINTS.TABLET_START)

  if (!isTablet && product.images.length <= 1) {
    return null
  }

  return (
    <div className="z-2 flex justify-center w-full gap-3 md:mt-4 md:gap-4 md:relative md:justify-start lg:mt-0 lg:flex-col lg:absolute lg:w-auto">
      {snaps.map(
        (snap, index) =>
          isTablet ? (
            <button
              key={snap}
              type="button"
              data-test-id="carousel-tracker-dot"
              className={cn(
                'w-[77px] h-[77px] overflow-hidden rounded-lg border-solid border border-grey-300 opacity-50 hover:opacity-100',
                selectedIndex === index && 'border-green-600 opacity-100',
              )}
              onClick={() => handleSelectImage(index)}
            >
              <ProductImage
                outOfStock={isProductDisabled}
                product={product}
                imageSrc={product.images[index].urlSmall}
                size={PRODUCT_IMAGE_SIZES.S}
                suppressImgGraying={!product.active}
              />
            </button>
          ) : (
            <div
              className={cn(
                'w-2 h-2 rounded-full bg-grey-450',
                selectedIndex === index && 'bg-blue-900',
              )}
            />
          ),
      )}
    </div>
  )
}

export default ImageSliderControls

import React from 'react'
import { identity } from 'lodash/fp'

import theme from 'theme'
import { useHeaderSize } from 'containers/App/LoggedInLayout/Header/HeaderSizeTracker'

import { XNormal } from 'components/Icons'
import BidfoodTemplate from './BidfoodTemplate'
import CreateTemplate from './CreateTemplate'

import {
  CreateTemplateToggleContainer,
  BidfoodTemplateContainer,
  CloseIconContainer,
  TopSegmentContainer,
} from './styles'

const TemplatesTopSegment = ({
  bidfoodTemplate,
  openInput,
  isMobile = false,
  closeDropdown = identity,
}) => {
  const { headerSize } = useHeaderSize()

  return (
    <TopSegmentContainer
      style={{
        top: headerSize,
      }}
    >
      <BidfoodTemplateContainer>
        <BidfoodTemplate template={bidfoodTemplate} {...{ closeDropdown }} />
        {isMobile && (
          <CloseIconContainer
            data-test-id="templates-mobile-close"
            onClick={closeDropdown}
          >
            <XNormal color={theme.colors.gray5} />
          </CloseIconContainer>
        )}
      </BidfoodTemplateContainer>
      <CreateTemplateToggleContainer>
        <CreateTemplate onClick={openInput} />
      </CreateTemplateToggleContainer>
    </TopSegmentContainer>
  )
}

export default TemplatesTopSegment

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { compose, isEmpty } from 'lodash/fp'
import { useParams } from 'react-router'
import { useIntl } from 'react-intl'
import usePrevious from 'use-previous'

import { cn } from 'utils'
import { scrollElementIntoView } from 'utils/dom'
import { useOfMinWidth } from 'hooks'
import useChangedLocationModal from 'hooks/useChangedLocationModal'
import useOnWindowWidthResize from 'hooks/useOnWindowWidthResize'
import { APP_BREAKPOINTS } from 'consts'
import Pagination from 'components/Pagination'
import ProductBox from 'components/Product/ProductBox'
import Breadcrumbs from 'components/Layout/Breadcrumbs/MainPage'
import TwButton, { BUTTON_VARIANTS } from 'components/Button/TwButton'
import {
  ArrowDownV2 as ArrowIcon,
  Filtering as FilteringIcon,
  XNormal as ResetIcon,
} from 'components/Icons'
import LoaderFullHeight from 'components/LoaderFullHeight'
import NoResults from 'components/NoResultsV2'
import { useItemFiltering } from 'components/Filters/utils'
import { useHeaderSize } from 'containers/App/LoggedInLayout/Header/HeaderSizeTracker'
import {
  useGetCampaignDetailsQuery,
  useLazyGetCampaignFiltersQuery,
  useLazyGetCampaignProductsQuery,
} from 'containers/Campaigns/rtkApi'
import { withPageView, SCREENS, ITEM_LIST_NAMES } from 'services/analytics'

import CampaignDetailsLayout from './CampaignDetailsLayout'
import CampaignFilters from './Filters'
import messages from './messages'

const CampaignDetails = () => {
  const { id } = useParams()
  const { formatMessage } = useIntl()
  const { headerSize } = useHeaderSize()

  const {
    data: campaignDetails = {},
    isLoading: isCampaignDetailsLoading,
  } = useGetCampaignDetailsQuery({ id })
  const { title, image = {}, urls = [], description, logoUrl } = campaignDetails

  const isDesktop = useOfMinWidth(APP_BREAKPOINTS.DESKTOP_START)

  const [isDescriptionOverflowing, setIsDescriptionOverflowing] = useState(
    false,
  )
  const [isDescriptionClamped, setIsDescriptionClamped] = useState(true)

  const {
    filteringSettings,
    updateFilteringSettings,
    availableFilters,
    itemsQueryData: {
      data = {},
      isLoading: isProductsLoading,
      isFetching: isProductsFetching,
    },
    filtersQueryData: {
      isLoading: isFiltersLoading,
      isFetching: isFiltersFetching,
    },
  } = useItemFiltering({
    id,
    fetchFiltersLazyQuery: useLazyGetCampaignFiltersQuery,
    fetchItemsLazyQuery: useLazyGetCampaignProductsQuery,
  })

  const isLoading =
    isProductsLoading || isFiltersLoading || isCampaignDetailsLoading
  const isFetching = isProductsFetching || isFiltersFetching

  const { products = [], pagination = {}, isFiltered = false } = data

  const handlePageChange = newPage => {
    updateFilteringSettings({
      page: newPage,
    })
  }

  const handleClearFilters = () => {
    updateFilteringSettings({
      filters: {},
    })
  }

  useChangedLocationModal({
    isReady: !isEmpty(campaignDetails),
  })

  const prevSettings = usePrevious(filteringSettings)
  useEffect(
    () => {
      if (prevSettings) {
        scrollElementIntoView('products-section', -headerSize)
      }
    },
    [filteringSettings],
  )

  const descriptionRef = useRef(null)
  const checkOverflow = () => {
    setIsDescriptionOverflowing(
      descriptionRef.current?.scrollHeight >
        descriptionRef.current?.clientHeight,
    )
  }

  const onWindowWidthChange = useCallback(() => {
    checkOverflow()
    setIsDescriptionClamped(true)
  }, [])

  useOnWindowWidthResize({
    onChange: onWindowWidthChange,
  })

  useEffect(
    () => {
      if (isDescriptionClamped) {
        checkOverflow()
      }
    },
    [products, description, isDescriptionClamped],
  )

  return (
    <>
      {(isLoading || isFetching) && <LoaderFullHeight />}
      <div className="flex flex-col w-full pb-14 lg:gap-10">
        <Breadcrumbs className="pt-4 px-6 lg:px-8" />
        <CampaignDetailsLayout
          isLoading={isLoading}
          image={
            <img
              alt={title}
              src={isDesktop ? image.webUrl : image.mobileUrl}
              className="rounded-3xl w-full object-cover h-[224px] md:h-[300px]"
            />
          }
          logo={
            logoUrl && (
              <img
                alt={title}
                src={logoUrl}
                className="object-contain object-left w-[145px] h-[60px] lg:w-[193px] lg:h-[80px]"
              />
            )
          }
          title={
            <span className="text-[32px] leading-[38px] lg:text-[40px] lg:leading-[50px] font-semibold">
              {title}
            </span>
          }
          description={
            description && (
              <p
                className={cn(
                  'text-[16px] leading-6 lg:text-xl lg:leading-8 [&_b]:font-bold',
                  products.length &&
                    isDescriptionClamped &&
                    'line-clamp-5 md:line-clamp-10',
                  products.length && 'text-13 lg:text-sm lg:leading-[22px]',
                )}
                ref={descriptionRef}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )
          }
          buttons={
            (!!urls.length || isDescriptionOverflowing) && (
              <div
                className={cn(
                  'flex flex-wrap gap-2 *:only:flex-initial *:flex-grow *:flex-shrink-0 *:basis-2/5 md:self-start md:grid md:grid-cols-4 lg:self-stretch lg:flex lg:*:basis-full lg:*:only:flex-1',
                  isDescriptionOverflowing &&
                    'lg:*:first:basis-2/5 lg:*:nth-2:basis-2/5',
                )}
              >
                {isDescriptionOverflowing && (
                  <TwButton
                    variant={BUTTON_VARIANTS.secondary}
                    onClick={() =>
                      setIsDescriptionClamped(!isDescriptionClamped)
                    }
                  >
                    <div className="flex items-center justify-center gap-4">
                      <span className="text-13">
                        {formatMessage(
                          isDescriptionClamped
                            ? messages.readMore
                            : messages.collapse,
                        )}
                      </span>
                      <div className="w-6 h-6 flex items-center justify-center self-end">
                        <ArrowIcon
                          className={cn(
                            '[&_path]:stroke-blue-900',
                            !isDescriptionClamped && 'rotate-180',
                          )}
                        />
                      </div>
                    </div>
                  </TwButton>
                )}
                {urls.map(({ name, url }) => (
                  <TwButton
                    key={name}
                    className="min-h-10"
                    variant={BUTTON_VARIANTS.primary}
                    onClick={() => window.open(url)}
                  >
                    <span className="text-13md">{name}</span>
                  </TwButton>
                ))}
              </div>
            )
          }
          products={
            (!!products.length || isFiltered) && (
              <div
                id="products-section"
                data-test-id="products-section"
                className="flex flex-col gap-4 md:gap-6 lg:gap-6"
              >
                <CampaignFilters
                  filteringSettings={filteringSettings}
                  updateFilteringSettings={updateFilteringSettings}
                  availableFilters={availableFilters}
                />

                {products.length ? (
                  <div className="grid grid-cols-[repeat(auto-fill,_minmax(176px,1fr))] px-2 gap-y-8 md:grid-cols-[repeat(auto-fill,_minmax(189px,1fr))] md:px-6 md:gap-x-2 md:gap-y-4 lg:p-0">
                    {products.map((product, index) => (
                      <ProductBox
                        itemListName={ITEM_LIST_NAMES.CAMPAIGNS}
                        itemListId={id}
                        key={product.id}
                        product={product}
                        index={index}
                        {...product}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="py-10">
                    <NoResults
                      text={formatMessage(messages.lackOfProducts)}
                      icon={<FilteringIcon />}
                      btnIcon={<ResetIcon />}
                      btnText={formatMessage(messages.clear)}
                      handleBtnClick={handleClearFilters}
                    />
                  </div>
                )}

                {pagination.totalPages > 1 && (
                  <div className="border-b border-t border-solid border-grey-300 py-4 md:[&>div]:justify-between! lg:[&>div]:justify-end!">
                    <Pagination
                      current={filteringSettings.page}
                      onChange={handlePageChange}
                      totalPages={pagination.totalPages}
                    />
                  </div>
                )}
              </div>
            )
          }
        />
      </div>
    </>
  )
}

export default compose(withPageView(SCREENS.CAMPAIGN_DETAILS))(CampaignDetails)

export const parseMaintenanceToast = response => {
  if (!response.items || response.items.length === 0) return null

  const toast = response.items[0]
  const includes = response.includes || {}

  const themeLink = toast.fields.theme
  const theme = includes.Entry?.find(
    entry =>
      entry.sys.id === themeLink.sys.id &&
      entry.sys.contentType.sys.id === 'maintenanceToastTheme',
  )

  return {
    text: toast.fields.body,
    theme: theme
      ? {
          color: theme.fields.fontColor,
          backgroundColor: theme.fields.backgroundColor,
        }
      : null,
  }
}

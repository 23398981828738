import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router'
import useEmblaCarousel from 'embla-carousel-react'

import { useQueryFilters } from 'hooks'
import { FILTER_EVENTS, trackSimpleEvent } from 'services/analytics'
import { useParsedChildGroups } from 'views/Products/utils'
import { isFetchingSelector } from 'hocs/selectors'
import {
  ALL_CATEGORIES_LIST_REDUCER_NAME,
  CATEGORY_LIST_REDUCER_NAME,
  CATEGORY_REDUCER_NAME,
  SELECTED_CATEGORY_GROUP_REDUCER_NAME,
  SELECTED_CATEGORY_REDUCER_NAME,
  SELECTED_CATEGORY_SUBGROUP_REDUCER_NAME,
} from 'containers/Categories'
import CarouselBorderFade from 'components/Carousel/CarouselBorderFade'
import TwButton, { BUTTON_VARIANTS } from 'components/Button/TwButton'
import { stringifyQuery } from 'utils'

import GroupButtonsSkeleton from './GroupButtonsSkeleton'

const GroupButtons = () => {
  const { selectedFiltersParams, searchQuery } = useQueryFilters()

  const queryParams = stringifyQuery({
    filters: selectedFiltersParams,
    search: searchQuery,
  })

  const [emblaRef] = useEmblaCarousel({
    dragFree: true,
  })

  const groups = useParsedChildGroups()
  const { fetching } = useSelector(
    isFetchingSelector([
      [CATEGORY_REDUCER_NAME, ALL_CATEGORIES_LIST_REDUCER_NAME],
      [CATEGORY_REDUCER_NAME, CATEGORY_LIST_REDUCER_NAME],
      [CATEGORY_REDUCER_NAME, SELECTED_CATEGORY_REDUCER_NAME],
      [CATEGORY_REDUCER_NAME, SELECTED_CATEGORY_GROUP_REDUCER_NAME],
      [CATEGORY_REDUCER_NAME, SELECTED_CATEGORY_SUBGROUP_REDUCER_NAME],
    ]),
  )

  if (fetching || groups?.length) {
    return (
      <div className="pb-4 overflow-hidden">
        <CarouselBorderFade />
        <div ref={emblaRef} className="w-full">
          <div className="flex *:not-last:mr-2 *:flex-[0_0_auto] *:max-w-full">
            {fetching ? (
              <GroupButtonsSkeleton />
            ) : (
              groups.map(({ id, name, path, productCount }) => (
                <Link
                  key={id}
                  to={`${path}${queryParams}`}
                  onClick={() => {
                    trackSimpleEvent(FILTER_EVENTS.category, { category: name })
                  }}
                >
                  <TwButton variant={BUTTON_VARIANTS.secondary}>
                    <div className="flex gap-2.5 items-center">
                      <span className="text-13 leading-6">{name}</span>
                      <span className="text-label-11 text-grey-600">
                        {productCount}
                      </span>
                    </div>
                  </TwButton>
                </Link>
              ))
            )}
          </div>
        </div>
      </div>
    )
  }

  return null
}

export default GroupButtons

import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

const HeaderSizeContext = createContext({
  headerRef: null,
  headerSize: 0,
})

export const useHeaderSize = () => useContext(HeaderSizeContext)

export const HeaderSizeTracker = ({ children }) => {
  const headerRef = useRef(null)
  const [headerSize, setHeaderSize] = useState(0)

  useEffect(() => {
    const element = headerRef.current
    if (!element) {
      return
    }

    const updateSize = () => {
      setHeaderSize(element.offsetHeight)
    }

    const observer = new ResizeObserver(() => updateSize())
    observer.observe(element)

    updateSize()

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <HeaderSizeContext value={{ headerRef, headerSize }}>
      {children}
    </HeaderSizeContext>
  )
}

import { FILTERS_TYPES, FILTERS_VALUES } from 'components/Filters/consts'
import { trackSimpleEvent } from './utils'

export const FILTER_EVENTS = {
  promotion: 'selected_promos_only',
  clearance: 'selected_clearances',
  onStock: 'selected_available_only',
  brands: 'selected_brand_filtering',
  productFeatures: 'selected_product_features_filtering',
  cuisineFeatures: 'selected_cuisine_filtering',
  storageTypes: 'selected_storage_filtering',
  category: 'selected_category_filtering',
  bestseller: 'selected_bestsellers',
  novelty: 'selected_novelties',
  bidmarket: 'selected_bidmarket',
  recommended: 'selected_recommended',
  clickedProductFeatures: 'clicked_product_features_filtering',
  clickedBrands: 'clicked_brand_filtering',
  clickedCuisines: 'clicked_cuisine_filtering',
  clickedOfferFeatures: 'clicked_label_filtering',
  clickedRecommended: 'clicked_recommended',
  clickedPromotions: 'clicked_promotions',
  clickedBestsellers: 'clicked_bestsellers',
  clickedNovelties: 'clicked_novelties',
  clickedClearances: 'clicked_clearances',
  clickedCuisine: 'clicked_cuisine',
  clickedCategories: 'clicked_category_filtering',
}

export const trackOfferFeaturesFilterEvent = (
  filterValues,
  featuresCounts,
  params,
) => {
  const {
    BESTSELLER: bestsellerFilterValue,
    NOVELTY: noveltyFilterValue,
    BIDMARKET: bidmarketFilterValue,
    RECOMMENDATION: recommendedFilterValue,
  } = FILTERS_VALUES.OFFER_FEATURES

  if (filterValues[bestsellerFilterValue]) {
    trackSimpleEvent(FILTER_EVENTS.bestseller, {
      totalCount: featuresCounts[bestsellerFilterValue],
      ...params,
    })
  }

  if (filterValues[noveltyFilterValue]) {
    trackSimpleEvent(FILTER_EVENTS.novelty, {
      totalCount: featuresCounts[noveltyFilterValue],
      ...params,
    })
  }

  if (filterValues[bidmarketFilterValue]) {
    trackSimpleEvent(FILTER_EVENTS.bidmarket, {
      totalCount: featuresCounts[bidmarketFilterValue],
      ...params,
    })
  }

  if (filterValues[recommendedFilterValue]) {
    trackSimpleEvent(FILTER_EVENTS.recommended, {
      totalCount: featuresCounts[recommendedFilterValue],
      ...params,
    })
  }
}

export const trackCatalogFilters = ({
  filterName,
  filterValues,
  offerFeatureCounts,
  params,
}) => {
  if (!Object.keys(filterValues).length || filterValues.all) return

  const {
    ON_STOCK,
    PROMOTION,
    BRANDS,
    STORAGE_TYPES,
    PRODUCT_FEATURES,
    CUISINE_FEATURES,
    OFFER_FEATURES,
    CLEARANCE,
    CATEGORIES,
  } = FILTERS_TYPES

  switch (filterName) {
    case ON_STOCK:
      trackSimpleEvent(FILTER_EVENTS.onStock, params)
      break
    case PROMOTION:
      trackSimpleEvent(FILTER_EVENTS.promotion, params)
      break
    case CLEARANCE:
      trackSimpleEvent(FILTER_EVENTS.clearance, params)
      break
    case BRANDS:
      trackSimpleEvent(FILTER_EVENTS.brands, {
        brands: Object.keys(filterValues).join(', '),
        ...params,
      })
      break
    case PRODUCT_FEATURES:
      trackSimpleEvent(FILTER_EVENTS.productFeatures, {
        productFeatures: Object.keys(filterValues).join(', '),
        ...params,
      })
      break
    case CUISINE_FEATURES:
      trackSimpleEvent(FILTER_EVENTS.cuisineFeatures, {
        cuisine: Object.keys(filterValues).join(', '),
        ...params,
      })
      break
    case STORAGE_TYPES:
      trackSimpleEvent(FILTER_EVENTS.storageTypes, {
        appliedFilters: Object.keys(filterValues).join(', '),
        ...params,
      })
      break
    case OFFER_FEATURES:
      trackOfferFeaturesFilterEvent(filterValues, offerFeatureCounts, params)
      break
    case CATEGORIES:
      trackSimpleEvent(FILTER_EVENTS.category, {
        brands: Object.keys(filterValues).join(', '),
        ...params,
      })
      break
    default:
  }
}

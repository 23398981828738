import React, { useEffect, useState } from 'react'

import Filters from 'components/Filters'
import { cn } from 'utils'
import { useHeaderSize } from 'containers/App/LoggedInLayout/Header/HeaderSizeTracker'
import { EVENT_ORIGIN } from 'services/analytics'

const CampaignFilters = ({
  filteringSettings,
  updateFilteringSettings,
  availableFilters,
}) => {
  const [portalNode, setPortalNode] = useState(null)
  const { headerSize } = useHeaderSize()

  useEffect(() => {
    setPortalNode(document.getElementById('campaign-filters-wrapper'))
  }, [])

  const resetFilters = () => {
    updateFilteringSettings({
      filters: {},
    })
  }

  const handleFiltersApply = (name, filters, isMultipleFilters) => {
    const newSelectedFilters = isMultipleFilters
      ? {
          ...filteringSettings.filters,
          ...filters,
        }
      : {
          ...filteringSettings.filters,
          [name]: { ...filters },
        }

    updateFilteringSettings({
      filters: newSelectedFilters,
    })
  }

  return (
    <div
      className="sticky z-[1] bg-white"
      id="campaign-filters-wrapper"
      style={{ top: headerSize - 1 }}
    >
      <Filters
        className={cn(
          '[&>.simplebar-track]:bottom-4 px-6 border-t border-b border-solid border-grey-300 py-5 [&_.simplebar-content]:*:last:pr-6 lg:border-t-0 lg:px-0 lg:[&_.simplebar-content]:*:last:pr-0',
        )}
        isCategoryFilter
        isTitle={false}
        handleFiltersClear={resetFilters}
        calculatePositionFromParent
        portalNode={portalNode}
        handleFiltersApply={handleFiltersApply}
        selectedFilters={filteringSettings.filters}
        availableFilters={availableFilters}
        eventParams={{
          origin: EVENT_ORIGIN.CAMPAIGNS,
        }}
      />
    </div>
  )
}

export default CampaignFilters

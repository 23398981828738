import { get } from 'lodash/fp'

import { useHeaderSize } from 'containers/App/LoggedInLayout/Header/HeaderSizeTracker'
import { HEADER_ID } from 'containers/App/LoggedInLayout/Header/consts'

export const scrollElementIntoView = (id, yOffset) => {
  const element = document.getElementById(id)
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

  window.scrollTo({ top: y, behavior: 'smooth' })
}

export const getTopHeaderOffset = () =>
  get('offsetHeight')(document.getElementById(HEADER_ID))

export const useScrollElementIntoView = id => {
  const { headerSize } = useHeaderSize()
  return () => scrollElementIntoView(id, -headerSize)
}

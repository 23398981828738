import styled from 'styled-components'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'
import { ProductInfoContainer } from 'views/ProductDetails/ProductInfoSection/styles'
import { ProductControlsContainer } from 'views/ProductDetails/CartControlsSection/styles'

export const ProductDetailsTopSection = styled.div`
  display: grid;
  gap: ${theme.spacing.md};
  width: 100%;

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    grid-template-columns: 1fr 1fr;

    ${ProductInfoContainer} {
      grid-column: span 2;
    }
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    grid-template-columns: 2fr 1fr;

    ${ProductInfoContainer} {
      grid-column: auto;
    }

    ${ProductControlsContainer} {
      grid-row: span 2;
    }
  }
`

import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'
import { TEMPLATE_ROW_MOBILE_MAX_WIDTH } from 'components/Product/consts'
import { APP_BREAKPOINTS } from 'consts'
import { STICKY_ELEMENTS_OFFSET } from 'views/Templates/styles'

export const ToolBarContainer = styled(Flex)`
  flex-direction: column;
  position: sticky;
  background-color: #fff;
  overflow: visible;
  z-index: ${theme.zIndex.base};
  padding-top: ${theme.spacing.sm};
  @media screen and (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    justify-content: space-between;
    // sticky offset
    margin-bottom: calc(100vh - ${STICKY_ELEMENTS_OFFSET}px);
    flex-direction: row;
    padding: ${theme.spacing.xs} ${theme.spacing.sm};
    border-bottom: 1px solid ${theme.colors.borderFaded};
  }
`

export const TemplateFiltersContainer = styled(Flex)`
  overflow: auto;
  width: 100%;
  align-items: center;
  @media screen and (max-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    padding-bottom: 16px;
    border-bottom: 1px solid ${theme.colors.borderFaded};

    // align horizontal scrollbar for smaller padding on mobile views
    .simplebar-track.simplebar-horizontal {
      bottom: -8px;
    }
  }
`

export const SearchSortingContainer = styled(Flex)`
  align-items: center;
  min-height: 40px;
  @media screen and (max-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    min-height: 72px;
    padding: 16px 0;
    margin-bottom: 16px;
  }
`
